import React, { useState, useEffect } from 'react';

function ProductDashboard() {
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    ACIN: '',
    DealCode: '',
    SerialNumber: '',
    productName: '',
    category: '',
    brand: '',
    processor: '',
    generation: '',
    ramMemory: '',
    storage: '',
    screenSize: '',
    displayType: '',
    graphicsCard: '',
    color: '',
    model: '',
    bodyDesign: '',
    stockQuantity: 0,
    price: '',
    imageUrl: null,
    imageArray: [],
    videoUrl: '',
  });

  useEffect(() => {
    fetchProducts();
    generateACIN();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch('http://www.abidcomputersmultan.com:3000/api/products');
      const data = await response.json();
      
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const generateACIN = () => {
    const timestamp = new Date().getTime();
    const randomSuffix = Math.floor(1000 + Math.random() * 9000);
    const acin = `ACIN-${timestamp}-${randomSuffix}`;
    setFormData((prevData) => ({ ...prevData, ACIN: acin }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      if (key === 'imageArray') {
        value.forEach((file) => formDataToSend.append(key, file));
      } else if (key === 'imageUrl' && value) {
        formDataToSend.append(key, value);
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await fetch('http://localhost:3000/api/products', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        fetchProducts(); // Refresh the product list
      } else {
        console.error('Failed to add product:', await response.text());
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'imageUrl') {
      setFormData({ ...formData, imageUrl: files[0] });
    } else if (name === 'imageArray') {
      setFormData({ ...formData, imageArray: Array.from(files) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h1 className="text-3xl font-bold text-center mb-6">Product Dashboard</h1>

      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md max-w-6xl mx-auto mb-8">
        <h2 className="text-xl font-semibold mb-4">Add New Product</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Fields */}
          {Object.keys(formData).map((field) => (
            <div key={field}>
              <label className="block text-sm font-medium text-gray-700" htmlFor={field}>
                {field}
              </label>
              {field === 'imageUrl' || field === 'imageArray' ? (
                <input
                  type="file"
                  name={field}
                  multiple={field === 'imageArray'}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded"
                />
              ) : (
                <input
                  type={field === 'price' || field === 'stockQuantity' ? 'number' : 'text'}
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded"
                />
              )}
            </div>
          ))}

          {/* Submit Button */}
          <button type="submit" className="mt-6 w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
            Add Product
          </button>
        </div>
      </form>

      {/* Product List */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Product List</h2>
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left">ID</th>
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">Category</th>
              <th className="px-4 py-2 text-left">Price</th>
              <th className="px-4 py-2 text-left">Image</th> {/* New column */}
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.ProductID}>
                <td className="px-4 py-2">{product.ProductID}</td>
                <td className="px-4 py-2">{product.productName}</td>
                <td className="px-4 py-2">{product.category}</td>
                <td className="px-4 py-2">{product.price}</td>
                <td className="px-4 py-2">
                  <img
                    src={product.imageUrl}
                    alt={product.productName}
                    className="h-20 w-20 object-cover rounded"
                  />
                  {console.log("Image URL:", product.imageUrl)} {/* Debugging */}
                </td>
              </tr>
              
            ))}
          </tbody>
        </table>
      </div>
      {products.map((product) => (
      <div class="relative flex flex-col justify-center overflow-hidden bg-gray-50 py-6 ">
        <div class="relative bg-white px-6 pb-8 pt-10 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
          <div class="">
            <img src={product.imageUrl} alt={product.productName} />
            <h3 class="text-lg font-bold">{product.productName}</h3>
            <h5>processor. 12th Gen Intel® Core™ i7-1265U, vPro® (12 MB cache, 10 cores, 12 threads, up to 4.80 GHz Turbo) · laptop. Windows 11 Pro</h5>
            <h4 class="font-bold text-lg">Rs. 100,000/-</h4>
            <div class="flex flex-row-reverse">
              <button class="inline-flex w-40 justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-orange-500 hover:bg-gray-50">Buy Now</button>
            </div>
          </div>
        </div>
      </div>
      ))}
    </div>
  );
}

export default ProductDashboard;
