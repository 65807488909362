import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);
  const [shippingInfo, setShippingInfo] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    countryRegion: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal: '',
    phone: '',
    email: ''
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(storedCartItems);
    const total = storedCartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotalPrice(total);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleCheckout = async () => {
    // Create a unique order tracking number (handled on the backend in real implementation)
    const orderTrackingNumber = `ORD-${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  
    // Prepare order details
    const orderDetails = {
      cartItems,
      shippingInfo,
      totalPrice,
      paymentMethod,
      orderTrackingNumber,
    };
  
    try {
      const response = await fetch('http://www.abidcomputersmultan.com:3000/checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderDetails),
      });
  
      if (response.ok) {
        alert('Checkout successful! Your order has been placed.');
        localStorage.removeItem('cartItems');
        navigate('/'); // Redirect to home or confirmation page
      } else {
        alert('There was an issue with your checkout. Please try again.');
      }
    } catch (error) {
      console.error('Checkout error:', error);
    }
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value); // Update the state with the selected payment method
  };
  

  const bg = "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/BG.png?alt=media&token=19dbc297-4316-45fa-aef5-2a511062841a"

  return (
    <section 
      className="py-10 px-4  min-h-screen" 
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="max-w-7xl mx-auto grid grid-cols-8 mb-10 gap-4">
        <div className="col-span-4">
          <h3 className="mt-5 p-2 text-xl font-bold text-gray-800">Billing Details</h3>
          <form>
            <div className="mb-6 grid gap-6 md:grid-cols-2">
              <div className="p-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">First name</label>
                <input type="text" name='firstName' value={shippingInfo.firstName} id="first_name" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="John" required />
              </div>
              <div className="p-2">
                <label  className="mb-2 block text-sm font-medium text-gray-900">Last name</label>
                <input type="text" name='lastName' value={shippingInfo.lastName} id="last_name" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="Doe" required />
              </div>
            </div>
            <div className="p-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">Company Name</label>
              <input type="text" name='companyName' value={shippingInfo.companyName} id="company" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="enter company name" required />
            </div>
            <div className="p-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">Country / Region</label>
              <input type="text" name='countryRegion' value={shippingInfo.countryRegion} id="country" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="Pakistan" required />
            </div>
            <div className="p-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">Street Address</label>
              <input type="tel" name='address1' value={shippingInfo.address1} id="address" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="House number and street name" required /> <br />
              <input type="tel" name='address2' value={shippingInfo.address2} id="address" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="Apartment, suite,unit, etc. (optional)" required />
            </div>
            <div className="p-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">Town / City</label>
              <input type="text" name='city' value={shippingInfo.city} id="city" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="Pakistan" required />
            </div>
            <div className="p-2">
              <label  className="mb-2 block text-sm font-medium text-gray-900">State / County</label>
              <input type="text" name='state' value={shippingInfo.state} id="state" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="Pakistan" required />
            </div>
            <div className="p-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">Postal Code / ZIP Code</label>
              <input type="tel" name='postal' value={shippingInfo.postal} id="postal" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="60000" required />
            </div>
            <div className="p-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">Phone number</label>
              <input type="tel" name='phone' value={shippingInfo.phone} id="phone" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="0300-123-4567" pattern="[0-9]{4}-[0-9]{3}-[0-9]{4}" required />
            </div>
            <div className="mb-6 p-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">Email address</label>
              <input type="email" name='email' value={shippingInfo.email} id="email" onChange={handleChange} className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500" placeholder="john.doe@company.com" required />
            </div>
          </form>
        </div>
        <div className="col-span-4">
          <h3 className="mt-5 text-xl font-bold text-gray-800 mb-10">Order Summary</h3>
          <ul className="mb-4">
            {cartItems.map((item, index) => (
              <li key={index} className="flex justify-between mb-2">
                <span>{item.productName} (x{item.quantity})</span>
                <span>Rs. {(item.price * item.quantity).toLocaleString()}</span>
              </li>
            ))}
          </ul>

          <div className="flex justify-between font-bold">
            <span>Total:</span>
            <span>Rs. {totalPrice.toLocaleString()}</span>
          </div>
          <div className="mt-10 flex flex-col bg-gray-100 bg-opacity-50 rounded-md p-10 gap-10">
            {/* Radio button for Direct bank transfer */}
            <div className="inline-flex items-center">
              <label className="relative flex cursor-pointer items-center">
                <input 
                  name="paymentMethod" 
                  type="radio" 
                  value="bankTransfer" 
                  className="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 transition-all checked:border-slate-400" 
                  checked={paymentMethod === 'bankTransfer'} // <-- Dynamic checked value
                  onChange={handlePaymentMethodChange} // <-- Change handler for radio button
                />
                <span className="absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-slate-800 opacity-0 transition-opacity duration-200 peer-checked:opacity-100"></span>
              </label>
              <label className="ml-2 cursor-pointer text-sm text-slate-600">
                <div>
                  <p className="font-medium">Direct bank transfer</p>
                </div>
              </label>
            </div>

            <p className="ms-7 rounded-md bg-orange-100 p-4 text-slate-500">
              Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.
            </p>

            {/* Radio button for Cash on Delivery */}
            <div className="inline-flex items-center">
              <label className="relative flex cursor-pointer items-center">
                <input 
                  name="paymentMethod" 
                  type="radio" 
                  value="cashOnDelivery" 
                  className="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 transition-all checked:border-slate-400" 
                  checked={paymentMethod === 'cashOnDelivery'} // <-- Dynamic checked value
                  onChange={handlePaymentMethodChange} // <-- Change handler for radio button
                />
                <span className="absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-slate-800 opacity-0 transition-opacity duration-200 peer-checked:opacity-100"></span>
              </label>
              <label className="ml-2 cursor-pointer text-sm text-slate-600">
                <div>
                  <p className="font-medium">Cash On Delivery</p>
                </div>
              </label>
            </div>
          </div>
          <button
            onClick={handleCheckout}
            className="mt-4 w-full bg-orange-500 text-white py-2 rounded shadow hover:bg-orange-600 transition"
          >
            Place Order
          </button>
        </div>
      </div>
    </section>
  );
};

export default Checkout;
