import React, { useState, useEffect } from 'react';

function ProductDashboard() {
  // eslint-disable-next-line
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    ACIN: '',
    DealCode: '',
    SerialNumber: '',
    productName: '',
    category: '',
    brand: '',
    processor: '',
    generation: '',
    ramMemory: '',
    storage: '',
    screenSize: '',
    displayType: '',
    graphicsCard: '',
    color: '',
    model: '',
    bodyDesign: '',
    stockQuantity: 0,
    price: '',
    imageUrl: null,
    imageArray: [],
    videoUrl: '',
  });

  useEffect(() => {
    fetchProducts();
    generateACIN();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch('http://www.abidcomputersmultan.com:3000/api/products');
      const data = await response.json();
      
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const generateACIN = () => {
    const timestamp = new Date().getTime();
    const randomSuffix = Math.floor(1000 + Math.random() * 9000);
    const acin = `ACIN-${timestamp}-${randomSuffix}`;
    setFormData((prevData) => ({ ...prevData, ACIN: acin }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      if (key === 'imageArray') {
        value.forEach((file) => formDataToSend.append(key, file));
      } else if (key === 'imageUrl' && value) {
        formDataToSend.append(key, value);
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await fetch('http://www.abidcomputersmultan.com:3000/api/products', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        fetchProducts(); // Refresh the product list
      } else {
        console.error('Failed to add product:', await response.text());
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'imageUrl') {
      setFormData({ ...formData, imageUrl: files[0] });
    } else if (name === 'imageArray') {
      setFormData({ ...formData, imageArray: Array.from(files) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <div className="min-h-screen p-6">

      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg max-w-6xl mx-auto mb-8">
        <h2 className="text-xl font-semibold mb-4">Add New Product</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Fields */}
          {Object.keys(formData).map((field) => (
            <div key={field}>
              <label className="block text-sm font-medium text-gray-700" htmlFor={field}>
                {field}
              </label>
              {field === 'imageUrl' || field === 'imageArray' ? (
                <input
                  type="file"
                  name={field}
                  multiple={field === 'imageArray'}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded"
                />
              ) : (
                <input
                  type={field === 'price' || field === 'stockQuantity' ? 'number' : 'text'}
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded"
                />
              )}
            </div>
          ))}

          {/* Submit Button */}
          <button type="submit" className="mt-6 w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
            Add Product
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProductDashboard;
