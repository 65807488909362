import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    const logo = "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/ACOSLOGO_GLOSSY.png?alt=media&token=a2089b70-0892-462b-b3e3-05c707176c25";
  
    return (
        <footer className="bg-white dark:bg-gray-900">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <a href="/" className="flex items-center mb-4">
                            <img src={logo} className="h-8 me-3" alt="Abid Logo" />
                            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Abid | Computers</span>
                        </a>
                        <span className="text-gray-500 dark:text-gray-400">We deal in all kinds of laptops and accessories!</span>
                    </div>
                    <div className="grid grid-cols-1 gap-8 sm:gap-6 md:grid-cols-3">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Contact us</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="https://www.facebook.com/profile.php?id=100085664331774" className="hover:underline"><FontAwesomeIcon icon={faFacebook} size="2s" className='me-2'/>Facebook</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://www.instagram.com/abidcomputersmux/" className="hover:underline"><FontAwesomeIcon icon={faInstagram} size='2s' className='me-2'/>Instagram</a>
                                </li>
                                <li>
                                    <a href="/" className="hover:underline"><FontAwesomeIcon icon={faWhatsapp} className='me-2'/>+92 320 9597197</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="/privacyPolicy" className="hover:underline">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/terms&conditions" className="hover:underline">Terms &amp; Conditions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                        © 2024 <a href="/" className="hover:underline">Abid Computer™</a>. All Rights Reserved.
                    </span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
