import React, { useState } from 'react';
import { useComparison } from '../context/CompareContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';

const ProductComparison = () => {
  const { comparisonList, removeFromComparison } = useComparison();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      {/* Trigger Button */}
      <button
        onClick={toggleModal}
        className="fixed bottom-20 mb-6 right-6 bg-orange-500 text-white rounded-full p-4 shadow-lg hover:bg-gray-600 transition flex items-center"
      >
        <FontAwesomeIcon icon={faBalanceScale} className="mr-2" />
        Compare ({comparisonList.length})
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-full max-w-4xl sm:max-w-6xl bg-white p-6 rounded-lg shadow-lg">
            {/* Modal Header */}
            <div className="flex justify-between items-center border-b pb-3">
              <h2 className="text-xl sm:text-2xl font-semibold">Product Comparison</h2>
              <button
                onClick={toggleModal}
                className="text-gray-500 hover:text-gray-800 transition sm:absolute sm:right-4 sm:top-4"
              >
                ✕
              </button>
            </div>

            {/* Modal Content */}
            {comparisonList.length === 0 ? (
              <p className="text-center mt-6">No products added for comparison.</p>
            ) : (
              <div className="overflow-x-auto mt-4">
                <table className="w-full border border-collapse">
                  <thead>
                    <tr className="border">
                      <th className="p-2 text-gray-700">Details</th>
                      {comparisonList.map((product) => (
                        <th className="border p-2" key={product.ProductID}>
                          <div className="flex flex-col items-center">
                            <img
                              className="w-32 sm:w-40 md:w-52"
                              src={product.imageUrl}
                              alt={product.productName}
                            />
                            <span className="text-sm font-bold">{product.productName}</span>
                            <h6 className="text-sm font-bold">Rs. {product.price}/-</h6>
                            <button
                              className="text-red-500 hover:underline mt-2"
                              onClick={() => removeFromComparison(product.ProductID)}
                            >
                              Remove
                            </button>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      'processor',
                      'generation',
                      'ramMemory',
                      'storage',
                      'screenSize',
                      'graphicsCard',
                      'displayType',
                    ].map((attribute) => (
                      <tr className="border" key={attribute}>
                        <th className="border p-2 capitalize" scope="row">
                          {attribute.replace(/([A-Z])/g, ' $1')}
                        </th>
                        {comparisonList.map((product) => (
                          <td className="border p-2" key={`${product.ProductID}-${attribute}`}>
                            {product[attribute] || 'N/A'}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {/* Modal Footer */}
            <div className="flex justify-end mt-4">
              <button
                onClick={toggleModal}
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductComparison;
