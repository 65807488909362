import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { useComparison } from '../context/CompareContext';

const ProductList = ({ categoryName }) => {

  const { addToComparison } = useComparison();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("Fetching products for category:", categoryName);  // Log categoryName
        const response = await axios.get(`http://www.abidcomputersmultan.com:3000/api/products/category/${categoryName}`);
        console.log(response.data);  // Log the fetched products
        setProducts(response.data);
      } catch (err) {
        setError('Error fetching products');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
  
    if (categoryName) {
      fetchProducts();
    }
  }, [categoryName]);
  
  const PreviousArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="bg-gray-900 text-white hover:bg-gray-700 rounded-full p-3 transition-all shadow-lg"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    );
  };

  PreviousArrow.propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="bg-gray-900 text-white hover:bg-gray-700 rounded-full p-3 transition-all shadow-lg"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    );
  };

  NextArrow.propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    ],
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mt-10 mb-10">
      <Slider {...sliderSettings}>
        {products.length === 0 ? (
          <p>No products found in this category</p>
        ) : (
          products.map((product) => (
            <div key={product.ProductID} className="p-2">
              <div className="bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition-all">
                <Link to={`/products/${product.ProductID}`} >
                  {product.imageUrl && <img  src={product.imageUrl} alt={product.productName} className="w-full h-auto object-cover rounded-md" />}
                  <div className="mt-4"> 
                    <h3 className="text-lg font-semibold">{product.productName}</h3>
                    <p className="text-gray-500">CPU: {product.processor}, Generation: {product.generation} </p>
                    <p className="text-xl font-bold text-orange-500">Rs. {product.price}</p>
                    
                  </div>
                </Link>
                <button
                  className=" "
                  onClick={() => addToComparison(product)}
                >
                  <FontAwesomeIcon icon={faBalanceScale} className="mt-2 text-white bg-gradient-to-r from-green-500 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" />
                  {/* Compare */}
                </button>
              </div>
            </div>
          ))
        )}
      </Slider>
    </div>
  );
};

export default ProductList;
