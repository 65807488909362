import React, { createContext, useContext, useState } from 'react';

const ComparisonContext = createContext();

export const useComparison = () => useContext(ComparisonContext);

export const ComparisonProvider = ({ children }) => {
  const [comparisonList, setComparisonList] = useState([]);

  const addToComparison = (product) => {
  console.log('Adding product:', product);

  if (!product) {
    console.error('Attempted to add an undefined product to the comparison list.');
    return;
  }

  setComparisonList((prevList) => {
    if (prevList.some((item) => item.ProductID === product.ProductID)) {
      alert('This product is already in the comparison list!');
      return prevList;
    }
    return [...prevList, product];
  });
};


const removeFromComparison = (productId) => {
  setComparisonList((prevList) => prevList.filter((item) => item.ProductID !== productId));
};


  return (
    <ComparisonContext.Provider
      value={{ comparisonList, addToComparison, removeFromComparison }}
    >
      {children}
    </ComparisonContext.Provider>
  );
};
