import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    // Fetch cart items from local storage or API
    const fetchCartItems = () => {
      const storedItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      setCartItems(storedItems);
      calculateTotalPrice(storedItems);
    };

    fetchCartItems();
  }, []);

  const calculateTotalPrice = (items) => {
    const total = items.reduce((sum, item) => sum + item.price * item.quantity, 0);
    setTotalPrice(total);
  };

  const handleRemoveItem = (id) => {
    const updatedItems = cartItems.filter(item => item.id !== id);
    setCartItems(updatedItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    calculateTotalPrice(updatedItems);
  };

  const handleQuantityChange = (id, quantity) => {
    const updatedItems = cartItems.map(item => 
      item.id === id ? { ...item, quantity: Math.max(quantity, 1) } : item
    );
    setCartItems(updatedItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    calculateTotalPrice(updatedItems);
  };

  const bg = "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/BG.png?alt=media&token=19dbc297-4316-45fa-aef5-2a511062841a"

  return (
    <section 
      className="py-10 px-4 min-h-screen " 
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
    <div className="mt-10 px-2">
      <div className="max-w-5xl mx-auto">
        <h1 className="text-2xl font-bold mb-4">Shopping Cart</h1>

        {cartItems.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          <div className=' p-10 rounded-md '>
            <ul className="space-y-4">
              {cartItems.map(item => (
                <li key={item.id} className="flex items-center justify-between p-4 border rounded-md">
                  <Link to={`/product/${item.id}`} className="flex items-center">
                    <img src={item.imageUrl} alt={item.productName} className="w-16 h-16 object-cover rounded-md" />
                    <span className="ml-4">{item.productName}</span>
                  </Link>
                  <div className="flex items-center">
                    <input
                      type="number"
                      value={item.quantity}
                      min="1"
                      onChange={(e) => handleQuantityChange(item.id, Number(e.target.value))}
                      className="border rounded-md w-12 text-center"
                    />
                    <span className="ml-2">Rs. {(item.price * item.quantity).toLocaleString()}</span>
                    <button
                      onClick={() => handleRemoveItem(item.id)}
                      className="ml-4 bg-red-500 text-white rounded-md px-2 py-1"
                    >
                      Remove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <h2 className="mt-6 text-xl font-semibold">Total Price: Rs. {totalPrice.toLocaleString()}</h2>
            <div className="mt-4 ">
              <Link to="/checkout">
                <button className="bg-orange-500 text-white px-4 py-2 rounded-md ">
                  Proceed to Checkout
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
    </section>
  );
};

export default Cart;
