import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const AboutPage = () => {
    const [showBackToTop, setShowBackToTop] = useState(false);
    
    useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const bg = "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/BG.png?alt=media&token=19dbc297-4316-45fa-aef5-2a511062841a";

  return (
    <section
        className="py-10 px-4  min-h-screen" 
        style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}
    >
    <div className='bg-white shadow-lg transition-all rounded-xl max-w-5xl mx-auto'>
    <div className="container mx-auto px-10 py-10">
      <div className="max-w-5xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">About Us</h1>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Company</h2>
          <p className="text-gray-600">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Abid Computers started their venture physically about 15 years back as a 
          laptop retailer at Multan Cantt, Multan. Their aim was to make technology available to the masses. The response that Abid 
          Computers received with their initiative was very overwhelming. Owing to the sales, it could be easily depicted that people 
          were quite content with buying with Abid Computers, and why not? They provided them the latest tech at the lowest price in Pakistan.
            Over time, in their attempt to reach out to the masses, they found out that their laptops, PCs and other accessories were being 
            sold online at much higher prices. Thus, killing the idea of reaching out to the masses of Pakistan, because clearly, according to 
            the statistics, more than 60% of the population is earning just enough to fulfill their basic needs. And having to pay higher prices 
            for tech products is simply not affordable for them!
            Abid Computers then went all the way from Abid Computers to www.abidcomputers.com and in a few years to www.abidcomputers.pk – one of 
            the biggest online tech stores in Pakistan. The journey however just started from then onwards. More the people found out about them, 
            more they started to like them. Today they have a whole community of people who stay connected to the tech world and well-benefitted 
            through Abid Computers page at facebook, tech blogging, and daily deals.
            <br /><br /> Let’s find out what is it that they have for people to like them so much?
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
          <p className="text-gray-600">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our mission is to provide our customers with top quality products. We strive to maintain the quality of our products, ensuring that
             our customers are always at the forefront of our priorities.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Online Store that sets all the facts right</h2>
          <p className="text-gray-600">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Let us admit we all are very busy in our own routines and do not have time to roam around shopping 
          for the right gadget that suits our needs. 
          Abid Computers does half of our homework by listing down all the facts and figures, like the features and specifications of a product alongside 
          a brief review so that we know what we can expect out of it. How responsively it will perform, how it looks from the outside, and what it carries 
          in the inside. Abid Computers answers all these questions regarding the products and delivers it right to your doorstep.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Offline store where you can visit and pick up your orders</h2>
          <p className="text-gray-600">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The foundation of the company was laid with a clear mindset; provide ease to the customers. 
          None of the online stores have an offline outlet in Pakistan, from where customers can visit or pick up their orders if they wish to. Which 
          is why Abid Computers introduced the concept of an offline outlet for an online store. Currently, the company has one outlet, which is located 
          at the Multan Cantt, Multan.
            <br />
            Abid Computers is planning on opening two more offline stores; one in Lahore and another in Islamabad.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Lowest Prices (Online) in Pakistan</h2>
          <p className="text-gray-600">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Abid Computers brings you brand new products with the seal of originality at an unbelievably 
          low price than other online suppliers. To maintain affordability and reach out the masses is what they basically intend to strive for. Whatever 
          the product; be it a tablet, DSLR Camera, laptop, smart watch, desktop, gaming console, or any other accessory, at Abidcomputers.pk you will find 
          it at the lowest prices online in Pakistan.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Keeping it Tech Savvy with all types of Tech Gadgets</h2>
          <p className="text-gray-600">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Abid Computers deals in all things tech and not just laptops. Yeah, initially they started as a 
          laptop retailer but now their collection has all that you can think of. Name any tech product you want and they will have it delivered to your doorstep. 
          In case, the huge list of products they are offering is not enough and you do not find what you are looking for, you can always leave a Product Addition 
          Request by leaving them a message, email or by placing a call at their customer support service.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Meet the Team</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Team Member 1 */}
            <div className="bg-white rounded-lg shadow-md p-4">
              <img
                src="https://via.placeholder.com/150"
                alt="Team Member 1"
                className="w-full h-32 object-cover rounded-md"
              />
              <h3 className="mt-2 font-semibold">John Doe</h3>
              <p className="text-gray-500">CEO</p>
            </div>
            {/* Team Member 2 */}
            <div className="bg-white rounded-lg shadow-md p-4">
              <img
                src="https://via.placeholder.com/150"
                alt="Team Member 2"
                className="w-full h-32 object-cover rounded-md"
              />
              <h3 className="mt-2 font-semibold">Jane Smith</h3>
              <p className="text-gray-500">CTO</p>
            </div>
            {/* Team Member 3 */}
            <div className="bg-white rounded-lg shadow-md p-4">
              <img
                src="https://via.placeholder.com/150"
                alt="Team Member 3"
                className="w-full h-32 object-cover rounded-md"
              />
              <h3 className="mt-2 font-semibold">Alex Johnson</h3>
              <p className="text-gray-500">Marketing Manager</p>
            </div>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p className="text-gray-600 mb-2">Have questions? We would love to hear from you!</p>
          <p className="text-gray-600">Email: <a href="mailto:info@example.com" className="text-orange-600 hover:underline">info@example.com</a></p>
          <p className="text-gray-600">Phone: <span className="text-orange-600">(123) 456-7890</span></p>
        </div>

        <Link to="/" className="text-orange-600 hover:underline"><FontAwesomeIcon icon={faChevronLeft} size={30}/>      Back to Home</Link>
      </div>
      {showBackToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-5 right-5 bg-indigo-500 hover:bg-indigo-600 text-white rounded-full p-4 shadow-lg transition-all transform hover:-translate-y-2"
        >
          <FontAwesomeIcon icon={faArrowUp} size="lg" />
        </button>
      )}
      </div>
      </div>
    </section>
  );
};

export default AboutPage;
