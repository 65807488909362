import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const PaymentPage = () => {
  const { orderTrackingNumber } = useParams();
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const navigate = useNavigate();

  const handlePayment = async () => {
    // Simulate processing card payment via POS system or bank API
    try {
      const paymentDetails = { cardNumber, expiryDate, cvv, orderTrackingNumber };
      const response = await fetch('http://www.abidcomputersmultan.com:3000/process-payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentDetails),
      });

      if (response.ok) {
        alert('Payment successful!');
        navigate('/confirmation');
      } else {
        alert('Payment failed. Please try again.');
      }
    } catch (error) {
      console.error('Payment error:', error);
    }
  };

  return (
    <div className="payment-page">
      <h1>Enter your Payment Details</h1>
      <form>
        <input 
          type="text" 
          placeholder="Card Number" 
          value={cardNumber} 
          onChange={(e) => setCardNumber(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Expiry Date (MM/YY)" 
          value={expiryDate} 
          onChange={(e) => setExpiryDate(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="CVV" 
          value={cvv} 
          onChange={(e) => setCvv(e.target.value)} 
        />
        <button type="button" onClick={handlePayment}>
          Pay Now
        </button>
      </form>
    </div>
  );
};

export default PaymentPage;
