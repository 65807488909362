

const PrivacyPolicy = () => {
    const bg = "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/BG.png?alt=media&token=19dbc297-4316-45fa-aef5-2a511062841a"

  return (
    <section 
        className="py-10 px-4  min-h-screen" 
        style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}
    >
    <div className="bg-white shadow-lg transition-all rounded-xl max-w-5xl mx-auto">
    <div className="container mx-auto px-10 py-10">
      <h1 className="text-4xl text-orange-500 font-bold mb-8">Privacy Policy</h1>
      <p className="mb-4">Welcome to Abid Computer. Your privacy is of utmost importance to us.</p>
      
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Information We Collect</h2>
        <p className="mb-2">
          When you visit our site, we collect certain information about your device, your interaction with the site, 
          and information necessary to process your purchases. We may also collect additional information if you 
          contact us for customer support.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">How We Use Your Information</h2>
        <p className="mb-2">
          We use your information to provide and improve our services, including fulfilling your orders, processing 
          payments, and offering customer support.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Sharing Your Information</h2>
        <p className="mb-2">
          We do not sell your personal information. However, we may share your information with trusted third parties 
          to help us operate our site, process transactions, or comply with legal obligations.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Your Rights</h2>
        <p className="mb-2">
          You have the right to access the personal information we hold about you and to ask that your personal 
          information be corrected, updated, or deleted. If you would like to exercise these rights, please contact 
          us through the information provided below.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Changes to This Policy</h2>
        <p className="mb-2">
          We may update this privacy policy from time to time to reflect changes to our practices or for other 
          operational, legal, or regulatory reasons.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Contact Us</h2>
        <p className="mb-2">
          For more information about our privacy practices, if you have questions, or if you would like to make a 
          complaint, please contact us by email at abidcomputers2022@gmail.com.
        </p>
      </section>
    </div>
    </div>
    </section>
  );
};

export default PrivacyPolicy;
