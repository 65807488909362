import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { faHeart, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { useComparison } from '../context/CompareContext';
import ProductComparison from '../components/Comparison';

const HomePage = ({ currentProduct, onAddToCompare, product }) => {
  const { comparisonList } = useComparison();
  const { addToComparison } = useComparison();
  const [isComparisonModalOpen, setComparisonModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedRamMemory, setSelectedRamMemory] = useState('');
  const [selectedGeneration, setSelectedGeneration] = useState('');
  const [selectedCpu, setSelectedCpu] = useState('');
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [showBrandOptions, setShowBrandOptions] = useState(false);
  const [showRamMemoryOptions, setShowRamMemoryOptions] = useState(false);
  const [showGenerationOptions, setShowGenerationOptions] = useState(false);
  const [showCpuOptions, setShowCpuOptions] = useState(false);
  // eslint-disable-next-line
  const [wishlist, setWishlist] = useState(() => JSON.parse(localStorage.getItem('wishlist')) || []);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const navigate = useNavigate();

  // Fetch products using the fetch API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('http://www.abidcomputersmultan.com:3000/api/products');
        const data = await response.json();
        setProducts(data);
      } catch (err) {
        console.error('Error fetching products:', err);
      }
    };

    fetchProducts();

    const handleScroll = () => setShowBackToTop(window.pageYOffset > 300);
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      nextVideo();
    }, 10000); // Switch every 10 seconds
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [currentVideoIndex]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const clearFilters = () => {
    setSelectedBrand(""); // Reset brand filter
    setSelectedRamMemory(""); // Reset RAM filter
    setSelectedGeneration(""); // Reset generation filter
    setSelectedCpu(""); // Reset CPU filter
  };
  
  const filteredProducts = products.filter(product => {
    const matchesBrand = selectedBrand ? product.brand === selectedBrand : true;
    const matchesRamMemory = selectedRamMemory ? product.ramMemory === selectedRamMemory : true;
    const matchesGeneration = selectedGeneration ? product.generation === selectedGeneration : true;
    const matchesCpu = selectedCpu ? product.processor === selectedCpu : true;
    return matchesBrand && matchesRamMemory && matchesGeneration && matchesCpu;
  });
  const handleAddToWishlist = (product) => {
    setWishlist(prevWishlist => {
      const newWishlist = [...prevWishlist, product];
      localStorage.setItem('wishlist', JSON.stringify(newWishlist)); // Save to localStorage
      return newWishlist;
    });
  };

  const closeComparisonModal = () => setComparisonModalOpen(false);
  const handleProductClick = (id) => {
    navigate(`/products/${id}`);
  };
  const bg = "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/BG.png?alt=media&token=19dbc297-4316-45fa-aef5-2a511062841a";

  const videoBanners = [
    "https://videos.pexels.com/video-files/3255275/3255275-uhd_2560_1440_25fps.mp4",
    "https://videos.pexels.com/video-files/6248612/6248612-uhd_2560_1440_25fps.mp4",
    "https://videos.pexels.com/video-files/6248600/6248600-uhd_2560_1440_25fps.mp4",
  ];

  const prevVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? videoBanners.length - 1 : prevIndex - 1
    );
  };
  
  const nextVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === videoBanners.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <section className="min-h-screen " 
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Video Carousel Section */}
      <div className="relative w-full h-[780px] mb-10">
        <video
          key={currentVideoIndex} // Ensure the video reloads when the index changes
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover"
        >
          <source
            src={videoBanners[currentVideoIndex]}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        {/* Overlay */}
        <div className="absolute inset-0 bg-black/30 flex flex-col items-center justify-center">
          <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
            Welcome to Our Store
          </h1>
          <p className="text-white text-lg md:text-xl mt-2 text-center">
            Discover the best deals and products
          </p>
        </div>

        {/* Carousel Controls */}
        <button
          onClick={prevVideo}
          className="text-orange-500 text-3xl hover:text-gray-900 transition-all absolute top-[50%] left-5 transform -translate-y-1/2 bg-black/50 p-3 rounded-full shadow-lg hover:bg-black/80"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          onClick={nextVideo}
          className="text-orange-500 text-3xl absolute top-[50%] right-5 transform -translate-y-1/2 bg-black/50 p-3 rounded-full shadow-lg hover:bg-black/80 transition"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      {/* <ShopByCategory /> */}
       {/* <Epcategory2 /> */}
       {/* <Comparison /> */}
      <div className="container max-w-8xl mx-auto grid grid-cols-1 lg:grid-cols-4 gap-8">
        {/* Sidebar */}
        <aside className="lg:col-span-1 bg-white p-6 rounded-lg shadow-lg mb-10">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-extrabold text-gray-800">Filters</h2>
            <button 
              onClick={clearFilters} 
              className="py-2 px-4 bg-red-600 text-white rounded-lg hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
            Clear Filters
            </button>
          </div>

          {/* Brand Filter */}
          <div className="mb-6">
            <h3 className="text-md font-semibold mb-3 text-gray-700 flex justify-between items-center">
            <span>Brand</span>
            <button
              onClick={() => setShowBrandOptions(!showBrandOptions)}
              className="text-gray-500"
            >
              {showBrandOptions ? '▲' : '▼'}
            </button>
            </h3>
            {showBrandOptions && (
              <div className="space-y-2">   
                {["Apple", "Asus", "Dell", "HP", "Lenovo"].map((brand) => (
                  <label key={brand} className="flex items-center">
                    <input 
                      type="checkbox" 
                      value={brand} 
                      checked={selectedBrand === brand} 
                      onChange={() => setSelectedBrand(selectedBrand === brand ? "" : brand)} 
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <span className="ml-2 text-gray-700">{brand}</span>
                  </label>
                ))}
              </div>
            )}
          </div>
          {/* RAM Filter */}
          <div className="mb-6">
            <h3 className="text-md font-semibold mb-3 text-gray-700 flex justify-between items-center">
              <span>RAM</span>
              <button
                onClick={() => setShowRamMemoryOptions(!showRamMemoryOptions)}
                className="text-gray-500"
              >
                {showRamMemoryOptions ? '▲' : '▼'}
              </button>
            </h3>
              {showRamMemoryOptions && (
                <div className="space-y-2">
                  {["04 GB", "06 GB", "08 GB", "16 GB", "32 GB", "64 GB"].map((ram) => (
                    <label key={ram} className="flex items-center">
                      <input 
                        type="checkbox" 
                        value={ram} 
                        checked={selectedRamMemory === ram} 
                        onChange={() => setSelectedRamMemory(selectedRamMemory === ram ? "" : ram)} 
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <span className="ml-2 text-gray-700">{ram}</span>
                    </label>
                  ))}
                </div>
                )}
          </div>
          {/* Generation Filter */}
          <div className="mb-6">
            <h3 className="text-md font-semibold mb-3 text-gray-700 flex justify-between items-center">
              <span>Generation</span>
              <button
                onClick={() => setShowGenerationOptions(!showGenerationOptions)}
                className="text-gray-500"
              >
                {showGenerationOptions ? '▲' : '▼'}
              </button>
            </h3>
            {showGenerationOptions && (
              <div className="space-y-2">
                {["1ST", "2ND", "3RD", "4TH", "5TH", "6TH", "7TH", "8TH", "9TH", "10TH", "11TH", "12TH", "Ryzen 5", "Ryzen 7", "Ryzen 9"].map((gen) => (
                <label key={gen} className="flex items-center">
                  <input 
                    type="checkbox" 
                    value={gen} 
                    checked={selectedGeneration === gen} 
                    onChange={() => setSelectedGeneration(selectedGeneration === gen ? "" : gen)} 
                    className="h-4 w-4 text-orange-600 border-gray-300 rounded focus:ring-orange-500"
                  />
                  <span className="ml-2 text-gray-700">{gen}</span>
                </label>
                ))}
              </div>
            )}
          </div>
          {/* Processor Filter */}
          <div className="mb-6">
            <h3 className="text-md font-semibold mb-3 text-gray-700 flex justify-between items-center">
              <span>Processor</span>
              <button
                onClick={() => setShowCpuOptions(!showCpuOptions)}
                className="text-gray-500"
              >
                {showCpuOptions ? '▲' : '▼'}
              </button>
            </h3>
            {showCpuOptions && (
              <div className="space-y-2">
                {["i3", "i5", "i6", "i7", "i9", "AMD", "Celeron", "Core M", "Xeon", "Pentium"].map((cpu) => (
                  <label key={cpu} className="flex items-center">
                    <input 
                      type="checkbox" 
                      value={cpu} 
                      checked={selectedCpu === cpu} 
                      onChange={() => setSelectedCpu(selectedCpu === cpu ? "" : cpu)} 
                      className="h-4 w-4 text-orange-600 border-gray-300 rounded focus:ring-orange-500"
                    />
                    <span className="ml-2 text-gray-700">{cpu}</span>
                  </label>
                ))}
              </div>
            )}
          </div>
        </aside>

        {/* Product Listings */}
        <section className="lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-10">
          {filteredProducts.length ? filteredProducts.map((product) => (
            <div key={product.ProductID}  className="relative flex flex-col bg-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition-transform transform hover:-translate-y-2 cursor-pointer">
              <img 
                src={product.imageUrl} 
                alt={product.productName} 
                className="w-full h-auto object-cover rounded-md" 
                onClick={() => handleProductClick(product.ProductID)} 
              />
              <h3 className="text-lg font-bold mt-5 text-gray-800">{product.productName}</h3>
              <h5 className="text-gray-500 mt-2">
                Generation: {product.generation} <br />
                RAM: {product.ramMemory} <br />
                Storage: {product.storage} <br />
                Processor: {product.processor}
              </h5>
              <h4 className="font-bold text-lg text-orange-500 mt-4">
                Rs. {product.price.toLocaleString('en-PK')}/-
              </h4>
              <div className="flex flex-row-reverse ">
                <button 
                  onClick={() => handleProductClick(product.ProductID)}
                  className="justify-center gap-x-1.5 rounded-md bg-orange-500 px-4 py-2 h-9 mt-2 text-sm font-semibold text-white shadow-md hover:bg-orange-600 transition"
                >
                  Buy Now
                </button>
                <button
                  className=" "
                  onClick={() => addToComparison(product)}
                >
                  <FontAwesomeIcon icon={faBalanceScale} className="mt-2 text-white bg-gradient-to-r from-green-500 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" />
                  {/* Compare */}
                </button>
                <button 
                  onClick={() => handleAddToWishlist(product)}
                  className="me-4"
                >
                  <FontAwesomeIcon icon={faHeart} size="2x"  className='text-red-500 hover:text-orange-800'/>
                </button>
                
              </div>
            </div>
          )) : <p>No products match your filters.</p>}
        </section>
      </div>

      {/* Comparison Modal */}
      <ProductComparison
        isOpen={isComparisonModalOpen}
        onClose={closeComparisonModal}
        comparisonList={comparisonList}
      />

      {/* Back to Top Button */}
      {showBackToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-6 right-6 bg-gray-500 text-white rounded-full p-4 shadow-lg hover:bg-orange-600 transition"
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      )}
    </section>
  );
};

export default HomePage;
