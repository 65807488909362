import React, { useState, useEffect } from 'react';

const ManageProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch products data from the backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('http://www.abidcomputersmultan.com:3000/api/products');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Manage Products</h1>
      <ul>
        {products.map((product) => (
          <li key={product.ProductID} className="mb-4">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-lg">{product.productName}</h2>
                <p className="text-sm">${product.price}</p>
              </div>
              <div>
                <button className="text-indigo-600 hover:underline mr-4">
                  Edit
                </button>
                <button
                  className="text-red-600 hover:underline"
                  onClick={() => handleDelete(product.ProductID)}
                >
                  Delete
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  // Handle product deletion
  const handleDelete = async (id) => {
    try {
      const response = await fetch(`http://localhost:3000/api/products/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete product');
      }

      // Remove the deleted product from state
      setProducts(products.filter((product) => product.ProductID !== id));
    } catch (error) {
      setError(error.message);
    }
  };
};

export default ManageProducts;
