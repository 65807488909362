const SalesReports = () => {
    const sales = [
      { id: 1, productName: "Product 1", quantitySold: 5, revenue: 500 },
      { id: 2, productName: "Product 2", quantitySold: 3, revenue: 300 },
    ];
  
    return (
      <div>
        <h1 className="text-2xl font-bold mb-4">Sales Reports</h1>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2">Product</th>
              <th className="py-2">Quantity Sold</th>
              <th className="py-2">Revenue</th>
            </tr>
          </thead>
          <tbody>
            {sales.map((sale) => (
              <tr key={sale.id}>
                <td className="py-2">{sale.productName}</td>
                <td className="py-2">{sale.quantitySold}</td>
                <td className="py-2">${sale.revenue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  export default SalesReports;
  