import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"; // Import styles
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ProductImages = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProduct = async () => {
          try {
            setLoading(true);
            const response = await fetch(`http://www.abidcomputersmultan.com:3000/api/products/${id}`);
            if (!response.ok) {
              throw new Error('Failed to fetch product');
            }
            const data = await response.json();
            console.log('Fetched Product:', data); // Log the product details
            setProduct(data);
        
            // Ensure the category exists before calling fetchRelatedProducts
            if (data.category) {
              
            } else {
              console.error('Product category is undefined');
            }
          } catch (err) {
            console.error('Error in fetchProduct:', err); // Log errors
          } finally {
            setLoading(false);
          }
        };
        fetchProduct();
      }, [id]);
    const images = [
        { original: product?.imageUrl || '', thumbnail: product?.imageUrl || '' },
        ...product?.imageArray?.map((img) => ({
          original: img,
          thumbnail: img,
        })) || [],
      ];
    
      if (product?.videoUrl) {
        images.push({
          renderItem: () => (
            <div className="aspect-w-16 aspect-h-9">
              <iframe
                className="w-full h-96 rounded-md shadow-lg"
                src={`https://www.youtube.com/embed/${extractYouTubeID(product.videoUrl)}`}
                title="Product Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ),
        });
      }
    
      const extractYouTubeID = (url) => {
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([\w-]+)/);
        return match ? match[1] : url;
      };
  return (
    <div>
      <div className="lg:col-span-1">
            <div className="relative">
              <ImageGallery 
                items={images} 
                showPlayButton={true} 
                showFullscreenButton={true} 
              />
            </div>
          </div>
    </div>
  )
}

export default ProductImages
